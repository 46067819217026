<template lang="pug">
.has-tooltip
  slot
  .tooltip {{ text }}
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.has-tooltip {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {

    .tooltip {
      opacity: 1;
    }
  }

  .tooltip {
    position: absolute;
    top: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    min-width: 45px;
    height: 30px;
    padding: 0 10px;
    border-radius: 4px;
    color: $white;
    box-shadow: 0 4px 14px 0 rgba($black, .25);
    font-size: 13px;
    opacity: 0;
    background: black;
    transition: $transition;

    &::before {
      content: '';
      position: absolute;
      bottom: -15px;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-top: 8px solid $black;
      border-right: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid transparent;
    }
  }
}
</style>
